import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/store/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      isGlobalPage: true,
    },
    component: () => import('@/layouts/AuthLayout/index.vue'),
    children: [
      {
        name: 'Login',
        path: 'login',
        meta: {
          isGlobalPage: true,
        },
        component: () => import('@/pages/auth/login/index.vue'),
      },
      {
        name: 'Recover password',
        path: 'recover-password',
        meta: {
          isGlobalPage: true,
        },
        component: () => import('@/pages/auth/recover-password/index.vue'),
      },
    ],
  },
  {
    path: '/panel',
    name: 'Panel',
    component: () => import('@/layouts/AppLayout/index.vue'),
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import('@/pages/panel/dashboard/index.vue'),
        children: [
          {
            name: 'Sales',
            path: 'sales',
            component: () => import('@/pages/panel/dashboard/children/sales/index.vue'),
            meta: {
              requiredPermission: 'User',
            },
          },
          {
            name: 'Product performance',
            path: 'product-performance',
            component: () => import('@/pages/panel/dashboard/children/product-performance/index.vue'),
            meta: {
              requiredPermission: 'User',
            },
          },
          {
            name: 'Retailer performance',
            path: 'retailer-performance',
            component: () => import('@/pages/panel/dashboard/children/retailer-performance/index.vue'),
            meta: {
              requiredPermission: 'User',
            },
          },
        ],
      },
      {
        name: 'Live monitor',
        path: 'live-monitor',
        component: () => import('@/pages/panel/live-monitor/index.vue'),
        meta: {
          requiredPermission: 'User',
        },
      },
      {
        name: 'Offers on the retailers per day',
        path: 'offers-per-day',
        component: () => import('@/pages/panel/offers-per-day/index.vue'),
        meta: {
          requiredPermission: 'Admin',
          permission: 'offers_per_day',
        },
      },
      {
        name: 'Users',
        path: 'users',
        component: () => import('@/pages/panel/users/index.vue'),
        meta: {
          requiredPermission: 'Admin',
        },
      },
      {
        name: 'UsersAdd',
        path: 'users/add',
        component: () => import('@/pages/panel/users/editor/index.vue'),
        meta: {
          requiredPermission: 'Admin',
        },
      },
      {
        name: 'UsersEdit',
        path: 'users/edit/:id',
        component: () => import('@/pages/panel/users/editor/index.vue'),
        meta: {
          requiredPermission: 'Admin',
        },
      },
      {
        name: 'Groups',
        path: 'groups',
        component: () => import('@/pages/panel/groups/index.vue'),
        meta: {
          requiredPermission: 'Admin',
        },
      },
      {
        name: 'GroupsAdd',
        path: 'groups/add',
        component: () => import('@/pages/panel/groups/editor/index.vue'),
        meta: {
          requiredPermission: 'Admin',
        },
      },
      {
        name: 'GroupsEdit',
        path: 'groups/edit/:id',
        component: () => import('@/pages/panel/groups/editor/index.vue'),
        meta: {
          requiredPermission: 'Admin',
        },
      },
      {
        name: 'Profile',
        path: 'profile',
        component: () => import('@/pages/panel/profile/index.vue'),
        meta: {
          requiredPermission: 'User',
        },
      },
      {
        name: 'Reports',
        path: 'reports',
        component: () => import('@/pages/panel/reports/index.vue'),
        meta: {
          requiredPermission: 'User',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.name) {
    next({ name: 'Sales' });
  }
  if (!to.meta.isGlobalPage && !useAuthStore().profile) {
    useAuthStore()
      .getUserProfile()
      .then(() => {
        if (
          to.meta.requiredPermission === 'Admin' &&
          !useAuthStore().profile?.roles.includes('ROLE_ADMIN') &&
          !(to.meta?.permission && useAuthStore().profile?.permissions?.find((perm) => perm.id === to.meta?.permission))
        ) {
          next({ name: 'Sales' });
        }
        next();
      })
      .catch(() => {
        next({ name: 'Login' });
      });
  } else if (
    to.meta.requiredPermission === 'Admin' &&
    useAuthStore().profile &&
    !useAuthStore().profile?.roles.includes('ROLE_ADMIN') &&
    !(to.meta?.permission && useAuthStore().profile?.permissions?.find((perm) => perm.id === to.meta?.permission))
  ) {
    next({ name: 'Sales' });
  } else {
    next();
  }
  // next();
});

export default router;
